import React, { useEffect, useState } from "react";
import { ASGColors, ASGTypography, Button, Grid } from "~components";
import cn from "classnames";
import COLORS from "~data/colors.json";
import typography from "~data/typography.json";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * @page
 * ASG Editor
 */
const ASG = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [activeForm, setActiveForm] = useState(`colour`);
  const [submitting, setSubmitting] = useState(false);

  const [colorBody, setColorBody] = useState(COLORS);
  const [typographyBody, setTypographyBody] = useState(typography);

  // ---------------------------------------------------------------------------
  // methods

  const writeJSON = () => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    fetch(`${process.env.GATSBY_SITE_URL}/api/write-colors`, {
      body: JSON.stringify(colorBody),
      headers: new Headers({
        "Content-Type": `application/json`
      }),
      method: `POST`
    })
      .then((res) => res.json())
      // eslint-disable-next-line no-unused-vars
      .then((res) => {
        // console.log(`res: `, res);

        setSubmitting(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    console.log(`typographyBody: `, typographyBody);
  }, [typographyBody]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Grid>
          <div className={styles.headerButtons}>
            <Button
              type="button"
              onClick={() => setActiveForm(`colour`)}
              className={cn({
                [styles.headerButtonActive]: activeForm === `colour`
              })}
            >
              <span className="b1">Colour</span>
            </Button>

            <Button
              type="button"
              onClick={() => setActiveForm(`type`)}
              className={cn({
                [styles.headerButtonActive]: activeForm === `type`
              })}
            >
              <span className="b1">Type</span>
            </Button>
          </div>
        </Grid>
      </header>

      {/* typography */}

      {activeForm === `type` && (
        <ASGTypography
          onUpdate={(updatedTypography) => setTypographyBody(updatedTypography)}
        />
      )}

      {/* colours */}

      {activeForm === `colour` && (
        <ASGColors onUpdate={(updatedColors) => setColorBody(updatedColors)} />
      )}

      <div className={styles.saveArea}>
        <Grid>
          <div className={styles.saveAreaContent}>
            <h2 className={cn(styles.saveAreaHeading, `h2`)}>Save changes</h2>

            <p className={cn(`b1`)}>
              Click this button once you’ve made all colour/type adjustments
              above.
            </p>

            <Button
              buttonType="submit"
              onClick={writeJSON}
              className={styles.saveAreaButton}
              disabled={submitting}
            >
              <span className="button-text">Save</span>
            </Button>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default ASG;
